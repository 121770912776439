import getLogger, { LogGroup } from "js/core/logger";
import { ds } from "js/core/models/dataService";
import { Presentation } from "js/core/models/presentation";
import { Slide } from "js/core/models/slide";
import Adapter from "js/core/storage/adapter";
import FirebaseAdapter from "js/core/storage/firebaseAdapter";
import { app } from "js/namespaces";
import { OLD_BASIC_CUTOFF } from "legacy-common/constants";
import { getCanvasBundle } from "legacy-js/canvas";
import { ThemeManager } from "legacy-js/core/themeManager";
import { $, _ } from "legacy-js/vendor";
import { auth } from "js/firebase/auth";
import { ISlidesMigratorRequest, ISlidesMigratorSuccessfulResponse } from "common/interfaces";
import { SharedThemes } from "legacy-js/core/models/sharedTheme";
import { BuiltInThemes, UserThemes } from "legacy-js/core/models/theme";

declare global {
    interface Window {
        migrator: {
            migrate: (request: ISlidesMigratorRequest & { authToken: string }) => Promise<ISlidesMigratorSuccessfulResponse>;
            initialize: () => void;
        };
        migratorPromise: Promise<void>;
    }
}

const themeManager = new ThemeManager();

const noop = () => { };

const logger = getLogger(LogGroup.MIGRATOR);

Adapter.setDefaultClass(FirebaseAdapter, { autoSync: false });

const CANVAS_WIDTH = 1280;
const CANVAS_HEIGHT = 720;

const targetAppVersion = 10;

class Migrator {
    private _canvas: any;

    public initialize() {
        logger.info("initialize");

        ds.prepare({ SharedThemes, BuiltInThemes, UserThemes });

        app.user = app.user || { get: noop, getEmail: noop };

        ds.dummySetup();
    }

    public async migrate({ slide, authToken }: ISlidesMigratorRequest & { authToken: string }) {
        logger.info("migrate()");

        // login with token
        const { user } = await auth().signInWithCustomToken(authToken);
        app.user = {
            id: user.uid,
            get: noop,
            getEmail: noop,
            on: noop,
            off: noop,
            isOldBasicUser: () => new Date(user.metadata.creationTime).getTime() < OLD_BASIC_CUTOFF,
            features: {
                isFeatureEnabled: () => true
            }
        };

        const presentation = await this._loadPresentation();

        const slideModel = new Slide(slide, { disconnected: true, presentation });

        await themeManager.loadTheme(presentation);

        // If slide is v9 or lower, first render v9, then v10
        const bundleVersion = await this._render(slideModel);
        if (bundleVersion !== targetAppVersion) {
            this._canvas.remove();
            slideModel.set("version", targetAppVersion);
            await this._render(slideModel);
        }

        slideModel.commit();

        const migratedSlide = slideModel.attributes;

        await this._completeTask();

        return { migratedSlide } as ISlidesMigratorSuccessfulResponse;
    }

    private async _loadPresentation() {
        logger.info("loadPresentation");

        const presentation = new Presentation(
            {
                id: _.uniqueId(),
                userId: app.user.id
            },
            { disconnected: true, autoLoad: false }
        );
        presentation.permissions.write = true;

        await themeManager.saveThemeToPresentation(ds.builtInThemes.first(), presentation);

        ds.selection.presentation = presentation;

        return presentation;
    }

    private async _render(slideModel: typeof Slide) {
        logger.info("render");

        const { SlideCanvas } = await getCanvasBundle(slideModel.get("version") ?? targetAppVersion);

        this._canvas = new SlideCanvas({
            dataModel: slideModel,
            canvasWidth: CANVAS_WIDTH,
            canvasHeight: CANVAS_HEIGHT,
            editable: false,
            isPlayback: true,
            isRenderingTemplateThumbnail: slideModel.get("isTeamSlideTemplate"),
            noThumbnail: true,
            slideIndex: 0,
            generateImages: false
        });
        app.currentCanvas = this._canvas;

        const scale = Math.min(
            window.innerWidth / CANVAS_WIDTH,
            window.innerHeight / CANVAS_HEIGHT
        );
        this._canvas.canvasScale = scale;

        $("body").append(this._canvas.render().$el);
        this._canvas.$el.css({
            position: "absolute",
            top: 0,
            left: 0,
            transform: `scale(${scale})`
        });

        await this._canvas.renderSlide();

        const updatedTemplateId = this._canvas.slideTemplate?.constructor?.updateTemplateId;
        if (updatedTemplateId) {
            if (this._canvas.slideTemplate.constructor.updateMigration) {
                this._canvas.slideTemplate.constructor.updateMigration(this._canvas);
            }

            this._canvas.model.template_id = updatedTemplateId;

            this._canvas.remove();

            return this._render(slideModel);
        }

        return this._canvas.bundleVersion;
    }

    private async _completeTask() {
        logger.info("completeTask");

        this._canvas.remove();

        await auth().signOut();
    }
}

window.migrator = new Migrator();
