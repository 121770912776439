//
// entry point
//

window.migratorPromise = (async () => {
    const { init } = await import(/* webpackMode: "eager" */ "js/firebase/init");
    await init();

    await import(/* webpackMode: "eager" */ "js/preload");

    import(/* webpackMode: "eager" */ "legacy-js/slidesMigrator/migrator");
})();
